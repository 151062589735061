<template>
    <section id="contact" class="contact">
        <div class="contact-links">
        <div class="contact-item" v-for="link in contactLinks" :key="link.name">
          <a :href="link.url" target="_blank" rel="noopener noreferrer">
            <img :src="getImage(link.logo)" :alt="link.name" class="contact-logo" />
            <p>{{ link.name }}</p>
          </a>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'ContactComponent',
    data() {
      return {
        contactLinks: [
          { name: 'Twitter X', url: 'https://x.com/millyclubsonsol', logo: 'twitter.png' },
          { name: 'Dexscreener', url: 'https://dexcreener.com', logo: 'dexcreener.png' },
          { name: 'Solscan', url: 'https://solscan.io', logo: 'solscan.png' },
          { name: 'Telegram', url: 'https://t.me/moneyfundverification', logo: 'telegram.png' },
          { name: 'Birdeye', url: 'https://birdeye.so', logo: 'birdeye.png' },
          { name: 'Dextools', url: 'https://dextools.io', logo: 'dextools.png' },
        ]
      };
    },
    methods: {
      getImage(imageName) {
        return require(`@/assets/${imageName}`);
      }
    }
  }
  </script>
  
  <style scoped>
  .contact {
    padding: 3rem 2rem;
    background: linear-gradient(135deg, #f0f0f0, #e0e0e0);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin: 2rem 0;
    border-radius: 12px;
    text-align: center;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .contact:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  }
  
  h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #00796b;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
  }
  
  h2::after {
    content: '';
    width: 80px;
    height: 4px;
    background: #00796b;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .contact-links {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
  }
  
  .contact-item {
    background: #fff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 150px;
  }
  
  .contact-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  .contact-logo {
    width: 50px;
    height: 50px;
    margin-bottom: 1rem;
  }
  
  .contact-item p {
    font-size: 1rem;
    color: #333;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .contact {
      padding: 2rem 1rem;
    }
  
    h2 {
      font-size: 2rem;
    }
  
    .contact-links {
      flex-direction: column;
      align-items: center;
    }
  
    .contact-item {
      width: 100%;
      max-width: 200px;
    }
  }
  </style>
  