<template>
    <header class="header">
      <img src="@/assets/logo.png" alt="Jew Solana Logo" class="logo" />
      <nav>
        <ul>
          <li><a href="#about">About</a></li>
          <li><a href="#tokenomics">Tokenomics</a></li>
          <li><a href="#roadmap">Roadmap</a></li>
          <li><a href="#team">Team</a></li>
          <li><a href="#contact">Link</a></li>
        </ul>
      </nav>
    </header>
  </template>
  
  <script>
  export default {
    name: 'HeaderComponent'
  }
  </script>
  
  <style scoped>
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #333;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .logo {
    height: 50px;
  }
  
  nav ul {
    list-style: none;
    display: flex;
    gap: 1.5rem; /* Increase gap for better spacing */
    margin: 0;
    padding: 0;
  }
  
  nav ul li {
    display: inline;
  }
  
  nav ul li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    position: relative;
    transition: color 0.3s ease;
    padding: 0.5rem;
    font-size: 1.2rem;
  }
  
  nav ul li a::before {
    content: '';
    position: absolute;
    width: 0;
    height: 3px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffd700;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }
  
  nav ul li a:hover::before {
    visibility: visible;
    width: 100%;
  }
  
  nav ul li a:hover {
    color: #ffd700;
    transform: scale(1.05);
  }
  
  nav ul li a:active {
    color: #e6b800;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      align-items: center;
    }
  
    .logo {
      margin-bottom: 1rem;
    }
  
    nav ul {
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;
      text-align: center;
    }
  
    nav ul li {
      width: 100%;
    }
  
    nav ul li a {
      display: block;
      padding: 0.75rem 0;
    }
  }
  </style>
  