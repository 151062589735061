<template>
    <section id="about" class="about">
      <h2>About $JEW</h2>
      <p>$JEW is a community-driven token on the Solana blockchain, designed to revolutionize the DeFi space with transparency and community empowerment. Our mission is to create a decentralized financial ecosystem that benefits everyone. Join us on this journey to financial freedom and innovation.</p>
    </section>
  </template>
  
  <script>
  export default {
    name: 'AboutComponent'
  }
  </script>
  
  <style scoped>
  .about {
    padding: 3rem 2rem;
    background: linear-gradient(135deg, #e0f7fa 0%, #e0f2f1 100%);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin: 2rem 0;
    border-radius: 12px;
    text-align: center;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .about:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  }
  
  h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #00796b;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
  }
  
  h2::after {
    content: '';
    width: 60px;
    height: 4px;
    background: #00796b;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  p {
    font-size: 1.25rem;
    line-height: 1.75;
    color: #004d40;
    max-width: 800px;
    margin: 0 auto;
    text-align: justify;
  }
  
  @media (max-width: 768px) {
    .about {
      padding: 2rem 1rem;
    }
  
    h2 {
      font-size: 2rem;
    }
  
    p {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .about {
      padding: 1.5rem 0.5rem;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    p {
      font-size: 0.875rem;
    }
  }
  </style>
  