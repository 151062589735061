<template>
    <section id="gallery-two" class="gallery">
      <div class="gallery-container">
        <div class="gallery-item" v-for="image in images" :key="image">
          <img :src="getImage(image)" :alt="`Image ${image}`" class="gallery-image" @click="showModal(image)" />
        </div>
      </div>
      <div v-if="isModalOpen" class="modal" @click="closeModal">
        <div class="modal-content">
          <span class="close" @click="closeModal">&times;</span>
          <img :src="getImage(selectedImage)" class="modal-image" />
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'ImageGalleryComponentTwo',
    data() {
      return {
        images: ['img5.jpg', 'img6.jpg', 'img7.jpg', 'img8.jpg'], // Add your new image here
        isModalOpen: false,
        selectedImage: null
      };
    },
    methods: {
      getImage(imageName) {
        return require(`@/assets/${imageName}`);
      },
      showModal(image) {
        this.selectedImage = image;
        this.isModalOpen = true;
      },
      closeModal() {
        this.isModalOpen = false;
        this.selectedImage = null;
      }
    }
  }
  </script>
  
  <style scoped>
  .gallery {
    padding: 3rem 2rem;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 1rem 0;
    border-radius: 8px;
    text-align: center;
  }
  
  h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .gallery-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }
  
  .gallery-item {
    flex: 1 1 calc(25% - 1rem); /* Adjust percentage based on number of images per row */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .gallery-item:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  .gallery-image {
    width: 100%;
    height: auto;
    display: block;
    cursor: pointer;
  }
  
  /* Modal Styles */
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    animation: fadeIn 0.3s ease;
  }
  
  .modal-content {
    position: relative;
    max-width: 80%;
    max-height: 80%;
    animation: scaleIn 0.3s ease;
  }
  
  .modal-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    font-size: 2rem;
    font-weight: bold;
    cursor: pointer;
    z-index: 1001;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes scaleIn {
    from { transform: scale(0.8); }
    to { transform: scale(1); }
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .gallery-item {
      flex: 1 1 calc(50% - 1rem);
    }
  }
  
  @media (max-width: 480px) {
    .gallery-item {
      flex: 1 1 calc(25% - 1rem); /* Four images per row on small screens */
      max-width: calc(25% - 1rem);
    }
  }
  </style>
  