<template>
    <section class="hero">
      <h1>Join the Future of DeFi with $JEW Token on Solana</h1>
      <p>Empowering the Community with Innovation and Trust</p>
      <button @click="buyJew" class="cta-button">Buy $JEW Now</button>
    </section>
  </template>
  
  <script>
  export default {
    name: 'HeroComponent',
    methods: {
      buyJew() {
        window.location.href = 'https://buy-jew-token.com'; // Update with actual link
      }
    }
  }
  </script>
  
  <style scoped>
  .hero {
    text-align: center;
    padding: 5rem 1rem;
    background-color: rgba(88, 180, 174, 0.8); /* Use a vibrant color with transparency */
    color: #fff;
    background-image: url('@/assets/banner.png'); /* Add background image path */
    background-size: cover;
    background-position: center;
    position: relative;
  }
  
  .hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Add a dark overlay for better text visibility */
    z-index: 1;
  }
  
  .hero h1,
  .hero p,
  .cta-button {
    position: relative;
    z-index: 2;
  }
  
  .hero h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #ffd700; /* Use a vibrant color for better contrast */
  }
  
  .hero p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    color: #ffd700; /* Use a vibrant color for better contrast */
  }
  
  .cta-button {
    padding: 1rem 2rem;
    font-size: 1.25rem;
    background-color: #ffd700; /* Use a vibrant color */
    color: #333;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #e6b800;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .hero {
      padding: 3rem 1rem;
    }
  
    .hero h1 {
      font-size: 2rem;
    }
  
    .hero p {
      font-size: 1rem;
    }
  
    .cta-button {
      padding: 0.75rem 1.5rem;
      font-size: 1rem;
    }
  }
  </style>
  