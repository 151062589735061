<template>
  <div>
    <HeaderComponent />
    <br>
    <HeroComponent />
    <AboutComponent />
    <ContactComponent />
    <ImageGalleryComponent />

    <TokenomicsComponent />
    <RoadmapComponent />
    <TeamComponent />
    <GameComponent />
    <ImageGalleryComponentTwo />

  </div>
</template>

<script>
import HeroComponent from '@/components/HeroComponent.vue'
import AboutComponent from '@/components/AboutComponent.vue'
import HeaderComponent from '@/components/HeaderComponent.vue'
import TokenomicsComponent from '@/components/TokenomicsComponent.vue'
import RoadmapComponent from '@/components/RoadmapComponent.vue'
import TeamComponent from '@/components/TeamComponent.vue'
import ContactComponent from '@/components/ContactComponent.vue'
import ImageGalleryComponent from '@/components/ImageGalleryComponent.vue'
import ImageGalleryComponentTwo from '@/components/ImageGalleryComponentTwo.vue'
import GameComponent from '@/components/GameComponent.vue'

export default {
  name: 'HomeView',
  components: {
    HeroComponent,
    HeaderComponent,
    AboutComponent,
    TokenomicsComponent,
    RoadmapComponent,
    TeamComponent,
    ContactComponent,
    ImageGalleryComponent,
    ImageGalleryComponentTwo,
    GameComponent
  }
}
</script>

<style scoped>
/* You can add some global styles if necessary */
</style>
