<template>
    <section id="game" class="game">
      <h2>Fun Game</h2>
      <p>Score: {{ score }} <span v-if="showTargetScore">/ 123</span></p>
      <div class="game-area">
        <img src="@/assets/logo.png" alt="Logo" class="logo" @click="startRain" />
        <div class="emoji-container">
          <div v-for="(emoji, index) in emojis" :key="index" :style="emoji.style" class="emoji" @click="collectPoint(index)">{{ emoji.symbol }}</div>
        </div>
      </div>
      <div v-if="score >= 5 && isRaining" class="logo-animation-container">
        <img src="@/assets/logo.png" alt="Logo Animation" class="logo-animation" />
      </div>
    </section>
  </template>
  
  <script>
  /* eslint-disable */
  export default {
    name: 'GameComponent',
    data() {
      return {
        emojis: [],
        score: 0,
        isRaining: false,
        hasShownTargetScore: false,
        emojiSymbols: ['💰', '💸', '💎', '🏦', '🏆', '🎁', '🤑', '👑', '💳'] // Emojis related to money, treasure, riches, luxury, etc.
      };
    },
    computed: {
      showTargetScore() {
        return this.hasShownTargetScore;
      }
    },
    methods: {
      startRain() {
        this.isRaining = true;
        for (let i = 0; i < 30; i++) {
          this.createEmoji();
        }
        setTimeout(() => {
          this.isRaining = false;
          if (!this.hasShownTargetScore && this.score >= 5) {
            this.hasShownTargetScore = true;
          }
        }, (15 + 10) * 1000); // Maximum duration of the rain animation
      },
      createEmoji() {
        const symbol = this.emojiSymbols[Math.floor(Math.random() * this.emojiSymbols.length)];
        const left = Math.random() * window.innerWidth + 'px';
        const animationDuration = Math.random() * 10 + 15 + 's'; // Even slower falling speed
        const emoji = {
          symbol: symbol,
          style: {
            left: left,
            animationDuration: animationDuration,
            animationDelay: Math.random() * 2 + 's'
          }
        };
        this.emojis.push(emoji);
        setTimeout(() => {
          this.removeEmoji(emoji);
        }, parseFloat(animationDuration) * 1000 + parseFloat(emoji.style.animationDelay) * 1000);
      },
      collectPoint(index) {
        this.score++;
        this.emojis.splice(index, 1);
      },
      removeEmoji(emoji) {
        const index = this.emojis.indexOf(emoji);
        if (index > -1) {
          this.emojis.splice(index, 1);
        }
      }
    },
    mounted() {
      window.addEventListener('click', this.startRain);
    },
    beforeUnmount() {
      window.removeEventListener('click', this.startRain);
    }
  }
  </script>
  
  <style scoped>
  .game {
    padding: 3rem 1rem;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 1rem 0;
    border-radius: 8px;
    text-align: center;
    position: relative;
  }
  
  h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .game-area {
    position: relative;
  }
  
  .logo {
    width: 100px;
    cursor: pointer;
  }
  
  .emoji-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    z-index: 9999; /* Ensure emojis appear above other content */
  }
  
  .emoji {
    position: absolute;
    top: 0;
    font-size: 2rem;
    opacity: 0;
    animation: fall linear forwards;
    pointer-events: auto; /* Enable clicking on emojis */
  }
  
  .logo-animation-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000; /* Ensure the logo animation appears above other content */
  }
  
  .logo-animation {
    width: 200px;
    height: auto;
    animation: growAndShrink 2s infinite;
  }
  
  @keyframes fall {
    0% {
      transform: translateY(-100vh); /* Start above the viewport */
      opacity: 1;
    }
    100% {
      transform: translateY(100vh); /* End below the viewport */
      opacity: 0;
    }
  }
  
  @keyframes growAndShrink {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
  </style>
  