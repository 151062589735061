<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>/* eslint-disable */ 
import HeaderComponent from './components/HeaderComponent.vue'
import AboutComponent from './components/AboutComponent.vue'

export default {
  name: 'App',
  components: {
    HeaderComponent,
    AboutComponent
  }
}
</script>

<style>
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f4f4f4;
}

#app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

@media (max-width: 768px) {
  #app {
    padding: 0.5rem;
  }
}
</style>
