<template>
    <section id="team" class="team">
        <h2>Our Team</h2>
        <p class="team-description">
            Our team consists of experienced and diverse individuals, working together to create a meme token that
            will take you on an unpredictable journey.
        </p>
        <div class="team-container">
            <div class="team-member" v-for="member in teamMembers" :key="member.name">
                <img :src="getImage(member.image)" :alt="member.name" class="team-photo" />
                <h3>{{ member.name }}</h3>
                <p>{{ member.role }}</p>
                <p>{{ member.description }}</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'TeamComponent',
    data() {
        return {
            teamMembers: [
                {
                    name: 'GORILLIONS',
                    role: 'Founder & CEO',
                    description: 'An experienced entrepreneur with a vision to create the next big meme token.',
                    image: 'teamman1.jpg'
                },
                {
                    name: 'Crypto',
                    role: 'Chief Marketing Officer',
                    description: 'A marketing genius known for her innovative strategies and viral campaigns.',
                    image: 'teamtwin.jpg'
                },
                {
                    name: 'r0ten',
                    role: 'Lead Developer',
                    description: 'A coding wizard with a knack for blockchain technology and smart contracts.',
                    image: 'teamgirl.jpg'
                },
          
                {
                    name: 'Anonymas',
                    role: 'Blockchain Expert',
                    description: 'A blockchain expert with deep knowledge of decentralized finance and security.',
                    image: 'teamman3.jpg'
                },
                {
                    name: 'Calee',
                    role: 'Financial Advisor',
                    description: 'A financial advisor who ensures our financial strategies are solid and profitable.',
                    image: 'teamman4.jpg'
                },
                {
                    name: 'Anonymas',
                    role: 'Graphic Designer',
                    description: 'A creative mind behind all our visual contents and branding materials.',
                    image: 'teamman5.jpg'
                }
            ]
        };
    },
    methods: {
        getImage(imageName) {
            return require(`@/assets/${imageName}`);
        }
    }
}
</script>

<style scoped>
.team {
    padding: 3rem 2rem;
    background: linear-gradient(135deg, #f0f0f0, #e0e0e0);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin: 2rem 0;
    border-radius: 12px;
    text-align: center;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.team:hover {
    transform: translateY(-10px);
    box-shadow:
        0 8px 30px rgba(0, 0, 0, 0.2);
}

h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #00796b;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
}

h2::after {
    content: '';
    width: 80px;
    height: 4px;
    background: #00796b;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.team-description {
    font-size: 1.25rem;
    color: #666;
    margin-bottom: 2rem;
    max-width: 800px;
    margin: 0 auto 2rem;
    text-align: center;
}

.team-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
}

.team-member {
    background: #fff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 250px;
}

.team-member {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.team-photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 1rem;
}

.team-member h3 {
    font-size: 1.5rem;
    margin: 0.5rem 0;
    color: #333;
}

.team-member p {
    font-size: 1rem;
    color: #666;
    margin: 0.5rem 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .team {
        padding: 2rem 1rem;
    }

    h2 {
        font-size: 2rem;
    }

    .team-description {
        font-size: 1rem;
    }

    .team-container {
        flex-direction: column;
        align-items: center;
    }

    .team-member {
        width: 100%;
        max-width: 300px;
    }
}
</style>

markdown