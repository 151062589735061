<template>
    <section id="tokenomics" class="tokenomics">
      <h2>Tokenomics</h2>
      <p>Total Supply: 1 ,000,000,000 $JEWMONEYONSOL</p>
      <ul>
        <li><span class="icon">🔒</span> Liquidity Locked</li>
        <li><span class="icon">🔑</span> Revoke Mint and Freeze Authority</li>
      </ul>
    </section>
  </template>
  
  <script>
  export default {
    name: 'TokenomicsComponent'
  }
  </script>
  
  <style scoped>
  .tokenomics {
    padding: 3rem 2rem;
    background: linear-gradient(135deg, #f0f0f0, #e0e0e0);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin: 2rem 0;
    border-radius: 12px;
    text-align: center;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .tokenomics:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  }
  
  h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #00796b;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
  }
  
  h2::after {
    content: '';
    width: 80px;
    height: 4px;
    background: #00796b;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  p {
    font-size: 1.5rem;
    line-height: 1.75;
    color: #004d40;
    max-width: 800px;
    margin: 0 auto 2rem;
    text-align: center;
  }
  
  ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  ul li {
    font-size: 1.25rem;
    color: #333;
    background: #fff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  ul li:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  .icon {
    margin-right: 1rem;
    font-size: 1.5rem;
    color: #00796b;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .tokenomics {
      padding: 2rem 1rem;
    }
  
    h2 {
      font-size: 2rem;
    }
  
    p {
      font-size: 1.25rem;
    }
  
    ul li {
      font-size: 1rem;
      padding: 0.75rem;
    }
  
    .icon {
      font-size: 1.25rem;
    }
  }
  </style>
  