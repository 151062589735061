<template>
    <section id="roadmap" class="roadmap">
      <h2>Roadmap</h2>
      <ul>
        <li>
          <div class="roadmap-item">
            <span class="icon">🚀</span>
            <div class="content">
              <h3>Q2 2024: Launch $JEW token and website</h3>
              <p>Kickstart the journey by launching the $JEW token and unveiling our official website.</p>
            </div>
          </div>
        </li>
        <li>
          <div class="roadmap-item">
            <span class="icon">📈</span>
            <div class="content">
              <h3>Q3 2024: Initial DEX Offering (IDO) and community building</h3>
              <p>Engage with the community through an Initial DEX Offering (IDO) and grow our user base.</p>
            </div>
          </div>
        </li>
        <li>
          <div class="roadmap-item">
            <span class="icon">💹</span>
            <div class="content">
              <h3>Q4 2024: Listing on major exchanges and strategic partnerships</h3>
              <p>Expand our reach by listing on major exchanges and forming strategic partnerships.</p>
            </div>
          </div>
        </li>
        <li>
          <div class="roadmap-item">
            <span class="icon">🔒</span>
            <div class="content">
              <h3>Q1 2025: Launch of staking and governance features</h3>
              <p>Introduce staking and governance features to empower the community with more control.</p>
            </div>
          </div>
        </li>
        <li>
          <div class="roadmap-item">
            <span class="icon">🌐</span>
            <div class="content">
              <h3>Q2 2025: Expansion of the ecosystem and global outreach</h3>
              <p>Broaden our ecosystem and reach a global audience with new and innovative features.</p>
            </div>
          </div>
        </li>
      </ul>
    </section>
  </template>
  
  <script>
  export default {
    name: 'RoadmapComponent'
  }
  </script>
  
  <style scoped>
  .roadmap {
    padding: 3rem 2rem;
    background: linear-gradient(135deg, #f0f0f0, #e0e0e0);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin: 2rem 0;
    border-radius: 12px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .roadmap:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  }
  
  h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #00796b;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
  }
  
  h2::after {
    content: '';
    width: 80px;
    height: 4px;
    background: #00796b;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .roadmap-item {
    display: flex;
    align-items: flex-start;
    background: #fff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .roadmap-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  .icon {
    font-size: 2rem;
    margin-right: 1rem;
    color: #00796b;
  }
  
  .content h3 {
    font-size: 1.5rem;
    margin: 0;
    color: #333;
  }
  
  .content p {
    font-size: 1.125rem;
    color: #666;
    margin: 0.5rem 0 0;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .roadmap {
      padding: 2rem 1rem;
    }
  
    h2 {
      font-size: 2rem;
    }
  
    ul {
      gap: 1rem;
    }
  
    .roadmap-item {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .icon {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  
    .content h3 {
      font-size: 1.25rem;
    }
  
    .content p {
      font-size: 1rem;
    }
  }
  </style>
  